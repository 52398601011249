import { Segment } from "#app/(unauthorized)/authentication/jwt";
import { useJwt } from "#app/(unauthorized)/authentication/useJwt";
import { getBlob, getJson } from "@/lib/fetchClient";
import { createQueryKeys } from "@lukemorales/query-key-factory";
import { queryOptions } from "@tanstack/react-query";

const GATEWAY_PATH = "bairdresearch/v1";

type ResearchHeadline = {
  id: number;
  headlineTitle: string;
  tickerSymbol: string;
  bairdRating: string;
  morningstarRating: string;
  valuelineRating: string;
  spRating: string;
  analyst: string | null;
  analystId: number | null;
  date: Date;
  suitability: string;
  fileSize: number | null;
  mediaType: string | null;
  fileName: string;
  deptSect: string | null;
  deptInd: string;
};

type PagedResearchHeadline = {
  bolResearchHeadlines: ResearchHeadline[];
  page: number;
  pageSize: number;
  totalRecordCount: number;
  totalPageCount: number;
};

type Analyst = {
  analystId: number;
  analystName: string;
};

type Rating = {
  ratingKey: string;
  companyName: string;
  ratingText: string;
};

type Sector = {
  deptIndustryCode: string;
  deptIndustryName: string;
  deptOrInd: string;
};

type Suitability = {
  suitabilityAbbr: string;
  suitabilityText: string;
  companyName: string;
};

export type GetResearchHeadlinesParams = Parameters<
  typeof getResearchHeadlines
>;
export type GetResearchHeadlinesResponse = Awaited<
  ReturnType<typeof getResearchHeadlines>
>;
export type GetResearchHeadlinesDocumentParams = Parameters<
  typeof getResearchHeadlinesDocument
>;
export type GetResearchHeadlinesDocumentResponse = Awaited<
  ReturnType<typeof getResearchHeadlinesDocument>
>;
export type GetResearchHeadlinesHasAccessResponse = Awaited<
  ReturnType<typeof getResearchHeadlinesHasAccess>
>;
export type GetResearchHeadlinesAnalystsResponse = Awaited<
  ReturnType<typeof getResearchHeadlinesAnalysts>
>;
export type GetResearchHeadlinesRatingsResponse = Awaited<
  ReturnType<typeof getResearchHeadlinesRatings>
>;
export type GetResearchHeadlinesSectorsResponse = Awaited<
  ReturnType<typeof getResearchHeadlinesSectors>
>;
export type GetResearchHeadlinesSuitabilitiesResponse = Awaited<
  ReturnType<typeof getResearchHeadlinesSuitabilities>
>;

type GetResearchHeadlinesFilters = {
  analystId?: string;
  deptInd?: string;
  deptSect?: string;
  headlineText?: string;
  page: number;
  pageSize: number;
  ratingKey?: string;
  suitability?: string;
  tickerSymbol?: string;
};

async function getResearchHeadlines(
  filters: GetResearchHeadlinesFilters,
): Promise<PagedResearchHeadline> {
  // Gather arguments as an iterable
  const searchParamsEntries = Object.entries(filters);

  // Omit empty values from searchParams
  const searchParams = new URLSearchParams();
  for (const [key, value] of searchParamsEntries) {
    if (value !== "all" && value !== null) {
      searchParams.set(key, String(value));
    }
  }
  if (searchParams.has("ratingKey")) {
    searchParams.set("ratingCompany", "Baird");
  }
  return await getJson<PagedResearchHeadline>(
    `/${GATEWAY_PATH}/ResearchHeadlines?${searchParams.toString()}`,
  );
}

// Blobs are NOT cacheable, do NOT use with `useQuery` or `createQueryKeys`
export async function getResearchHeadlinesDocument(
  headlineId: number,
): Promise<Blob> {
  return await getBlob(
    `/${GATEWAY_PATH}/ResearchHeadlines/Document?headlineId=${headlineId}`,
  );
}

async function getResearchHeadlinesHasAccess(): Promise<boolean> {
  return await getJson<boolean>(
    `/${GATEWAY_PATH}/ResearchHeadlines/HasAccess`,
  ).catch(() => false);
}

async function getResearchHeadlinesAnalysts(): Promise<Analyst[]> {
  return await getJson<Analyst[]>(
    `/${GATEWAY_PATH}/ResearchHeadlines/Analysts`,
  );
}

async function getResearchHeadlinesRatings(): Promise<Rating[]> {
  return await getJson<Rating[]>(`/${GATEWAY_PATH}/ResearchHeadlines/Ratings`);
}

async function getResearchHeadlinesSectors(): Promise<Sector[]> {
  return await getJson<Sector[]>(`/${GATEWAY_PATH}/ResearchHeadlines/Sectors`);
}

async function getResearchHeadlinesSuitabilities(): Promise<Suitability[]> {
  return await getJson<Suitability[]>(
    `/${GATEWAY_PATH}/ResearchHeadlines/Suitabilities`,
  );
}

export const bairdResearchQueryKeys = createQueryKeys("bairdResearch", {
  headlines: (paramsObj: GetResearchHeadlinesParams[0]) => ({
    queryKey: [paramsObj],
    queryFn: () => getResearchHeadlines(paramsObj),
  }),
  hasAccess: {
    queryKey: null,
    queryFn: () => getResearchHeadlinesHasAccess(),
  },
  analysts: {
    queryKey: null,
    queryFn: () => getResearchHeadlinesAnalysts(),
  },
  ratings: {
    queryKey: null,
    queryFn: () => getResearchHeadlinesRatings(),
  },
  sectors: {
    queryKey: null,
    queryFn: () => getResearchHeadlinesSectors(),
  },
  suitabilities: {
    queryKey: null,
    queryFn: () => getResearchHeadlinesSuitabilities(),
  },
});

export function useQueryOptionsResearchHeadlinesHasAccess() {
  const jwt = useJwt();
  const hadNoRequiredActivities = jwt.requiredActivities.length === 0;
  const hasRoleAccess = [
    Segment.IndividualInvestors,
    Segment.PrivateAssetManagement,
  ].includes(jwt.segment);

  if (hadNoRequiredActivities && hasRoleAccess) {
    return queryOptions({
      queryKey: bairdResearchQueryKeys.hasAccess.queryKey,
      queryFn: (context) => bairdResearchQueryKeys.hasAccess.queryFn(context),
      staleTime: Infinity,
      gcTime: 0,
      throwOnError: false,
    });
  }

  return queryOptions({
    queryKey: bairdResearchQueryKeys.hasAccess.queryKey,
    queryFn: () => false,
    staleTime: 0,
    throwOnError: false,
  });
}
